<template>
    <div class="admin-role">
        <div class="role-box">
            <div class="title-box">
                <p>
                    <span style="margin-right: 10px; font-size: 14px"
                        >角色名称</span
                    >
                    <el-input
                        placeholder="请输入内容"
                        size="small"
                        style="width: 200px"
                        v-model="input"
                        clearable
                    >
                    </el-input>
                    <el-button
                        type="primary"
                        size="small"
                        style="margin-left: 10px"
                        @click="getRoleList"
                        >查询</el-button
                    >
                </p>
            </div>
            <div class="table">
                <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    border
                    stripe
                    height="100%"
                    style="width: 100%; font-size: 0.8rem; color: #353535"
                    :header-cell-style="{
                        background: '#F9F9F9',
                        color: '#353535',
                        'font-size': '0.9rem',
                        'font-weight': 'bold',
                        'text-align': 'center',
                    }"
                >
                    <el-table-column
                        prop="name"
                        label="角色名称"
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="remark"
                        label="备注"
                        align="center"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="nickName"
                        label="创建人"
                        align="center"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        fixed="right"
                        align="center"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                style="font-size: 0.75rem"
                                @click.stop="onEdit(scope.row)"
                            >
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="pages">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="currentPage"
                    :page-size="pagesize"
                    :page-sizes="[10, 20, 50]"
                    layout="total,prev, pager, next, jumper"
                    :total="total"
                    prev-text="上一页"
                    next-text="下一页"
                ></el-pagination>
            </div>
        </div>
        <!-- 修改 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="editDrawer"
            :direction="direction"
            :before-close="handleClose"
            size="800px"
        >
            <template slot="title">
                <div style="flex: 1; display: flex; align-items: center">
                    <span class="vertical"></span>
                    修改角色
                </div>
            </template>
            <EditRole :item="item" @close="handleClose" />
        </el-drawer>
    </div>
</template>
<script>
import { roleList, adminMenuList } from '@/api/admin/role/role.js';
import EditRole from './editRole.vue';
export default {
    components: {
        EditRole,
    },
    data() {
        return {
            input: '',
            tableData: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            editDrawer: false,
            direction: 'rtl',
            item: '',
        };
    },
    mounted() {
        this.getRoleList();
    },
    methods: {
        getRoleList() {
            let data = {
                param: {
                    name: this.input,
                    status: 1,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            roleList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getRoleList();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getRoleList();
        },

        onEdit(row) {
            this.editDrawer = true;
            let that = this;
            setTimeout(() => {
                that.item = row;
            }, 100);
        },
        handleClose() {
            this.editDrawer = false;
            this.getRoleList();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.admin-role {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .role-box {
        background: #fff;
        flex: 1;
        display: flex;
        flex-direction: column;
        .title-box {
            padding: 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button {
                background: #2370eb;
                border: none;
            }
        }
        .table {
            padding: 0 10px;
            flex: 1;
        }
    }
    .pages {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }
}
</style>
