<template>
    <div class="editCoreRole">
        <p class="top">
            <span> 角色名称</span>
            <el-input
                placeholder="请输入角色名称"
                size="small"
                style="width: 150px"
                v-model="partnerName"
                clearable
            >
            </el-input>
            <span style="margin-left: 10px"> 角色备注</span>
            <el-input
                placeholder="请输入角色备注"
                v-model="remark"
                clearable
                style="width: 150px"
                size="small"
            >
            </el-input>
            <span style="margin-left: 10px; width: 100px">月度销售额度</span>
            <el-input
                v-model.number="expenseAmount"
                size="small"
                style="width: 150px"
                placeholder="请输入内容"
            ></el-input>
            <span class="toast">（将在次月更新此销售额度）</span>
        </p>
        <div style="flex: 1; overflow: auto">
            <el-tree
                :data="dataList"
                ref="tree"
                :render-content="renderContent"
                node-key="id"
                show-checkbox
                highlight-current
                :default-expand-all="expandAll"
                :default-checked-keys="Mrxz"
                :default-expanded-keys="Mrxz"
                @node-expand="handleExpand"
                :props="defaultProps"
            ></el-tree>
        </div>
        <div style="text-align: center; margin-top: 2rem; padding-bottom: 20px">
            <el-button
                type="plain"
                size="small"
                style="background: #eaeaea; border: none"
                @click="close"
                >取消</el-button
            >
            <el-button
                type="primary"
                size="small"
                style="background: #2370eb; border: none"
                @click="submit"
                >确认</el-button
            >
        </div>
    </div>
</template>

<script>
import { adminMenuList, roleUpdate } from '@/api/admin/role/role.js';
export default {
    name: 'editCoreRole',
    props: ['item'],
    data() {
        return {
            partnerName: '',
            remark: '',
            expenseAmount: '',
            activeName: 'first',
            id: '',
            Mrxz: [],
            expandAll: false,
            checkedKeys: [],
            defaultProps: {
                label: 'name',
                children: 'children',
            },
            dataList: [],
            accessibleList: [],
        };
    },
    watch: {
        item() {
            this.id = this.item.id;
            this.remark = this.item.remark;
            this.partnerName = this.item.name;

            this.modify();
        },
    },
    methods: {
        // 获取菜单
        getMenu() {
            let data = {
                param: {},
            };
            adminMenuList(data).then((res) => {
                this.dataList = this.$Tree.treeDataTranslateAudit(
                    res.data,
                    'id',
                    'parentId'
                );
            });
        },

        modify() {
            let data = {
                param: {
                    id: this.id,
                },
            };
            this.Mrxz = [];
            adminMenuList(data).then((res) => {
                let list = this.$Tree.treeDataTranslateAudit(
                    res.data,
                    'id',
                    'parentId'
                );
                var lastchildren = [];
                forxh(list);
                function forxh(list) {
                    for (var i = 0; i < list.length; i++) {
                        var chlist = list[i];
                        if (chlist.children && chlist.children.length > 0) {
                            forxh(chlist.children);
                        } else {
                            lastchildren.push(chlist);
                        }
                    }
                }
                for (let j = 0; j < lastchildren.length; j++) {
                    this.Mrxz.push(lastchildren[j].id);
                }
                this.getMenu();
            });
        },

        handleExpand() {
            //节点被展开时触发的事件
            //因为该函数执行在renderContent函数之前，所以得加定时
            // setTimeout(() => {
            //     this.changeCss();
            // }, 10);
        },
        renderContent(h, { node, data, store }) {
            //树节点的内容区的渲染 Function

            let classname = '';
            // 由于项目中有三级菜单也有四级级菜单，就要在此做出判断

            if (node.level === 2 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            if (node.level === 1 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            if (node.level === 3 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            return h(
                'p',
                {
                    class: classname,
                },
                node.label
            );
        },
        handleExpands() {
            setTimeout(() => {
                this.changeCss();
            }, 10);
        },
        changeCss() {
            var levelName = document.getElementsByClassName('foo'); // levelname是上面的最底层节点的名字
            for (var i = 0; i < levelName.length; i++) {
                // cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
                levelName[i].parentNode.style.cssFloat = 'left'; // 最底层的节点，包括多选框和名字都让他左浮动
                levelName[i].parentNode.onmouseover = function () {
                    this.style.backgroundColor = '#fff';
                };
            }
        },
        submit() {
            this.addRoleArr = [];
            let res = this.$refs.tree.getHalfCheckedKeys();
            let res1 = this.$refs.tree.getCheckedKeys();
            let res2 = res.concat(res1);
            res2.forEach((item) => {
                this.addRoleArr.push({
                    menuId: item,
                    roleId: this.id,
                });
            });
            console.log(this.addRoleArr);
            if (this.partnerName == '') {
                this.$message.error('角色名称不能为空');
            } else if (this.remark == '') {
                this.$message.error('备注不能为空');
            } else {
                // console.log(this.addRoleArr);
                let data = {
                    param: {
                        name: this.partnerName,
                        remark: this.remark,
                        id: this.id,
                        roleMenuDOList: this.addRoleArr,
                        expenseAmount: this.expenseAmount,
                    },
                };
                roleUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.close();
                        this.$message.success('修改角色成功');
                    }
                });
            }
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.editCoreRole {
    width: 100%;
    height: 100%;
    color: #000;
    display: flex;
    flex-direction: column;

    .top {
        font-size: 14px;
        padding: 1rem;
        display: flex;
        align-items: center;
        position: relative;
        span {
            display: block;
            width: 65px;
        }
        /deep/ .el-input__inner {
            width: 150px;
            background: #f1f3f7;
            border-radius: 5px;
        }
        .toast {
            position: absolute;
            right: 75px;
            bottom: 0;
            color: #666;
            font-size: 12px;
            width: auto;
            white-space: nowrap;
        }
    }
    .roleList {
        padding-bottom: 20px;
    }
    .roleTitle {
        padding: 10px 20px;
        margin: 20px;
        font-size: 14px;
        background: #f0f2f4;
    }
}
.container {
    padding: 40px;
}
</style>
